import React from "react"
// import '../i18n/i18n';
// import "./layout.css"

import {
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import CssBaseline from '@material-ui/core/CssBaseline';
import { globalStyles } from '../styles/global-styles'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ParallaxProvider } from 'react-scroll-parallax';
import CookieConsent from "react-cookie-consent";


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4682b4',
      main: '#2c466b',
      dark: '#4682b4', // hover miatt
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'century-gothic',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          margin:0,
          padding:0,
        },
        
        ':root': {
          '--cb-blue': 'rgb(44, 70, 107)',
          '--cb-green': 'rgb(172, 212, 51)',
        },
        
        p: {
          marginBottom: '1.45rem',
          
        },
        
        html: {
          WebkitFontSmoothing: 'auto',
          
        },
        body: {
          backgroundColor: 'rgba(234,241,243,0.15)',
          color: 'rgba(44,70,107,1)',
          fontFamily: 'century-gothic, sans-serif',
        },
        
        main: {
           '& p': {
              fontSize: '20px',
              lineHeight: '1.4',
              marginTop: 14,
              fontWeight: 400,
              whiteSpace: 'pre-wrap',
              marginBottom: 14,
              textAlign: 'left',
            },
        
            '& li': {
              
              fontSize: 20,
            },
        
            '& ul': {
              marginTop: 16,
              marginBottom: 16,
              '& li': {
                listStyle: 'unset',
              }
            },
            '& ol': {
              '& li': {
                listType: 'unset',  
              }
            },
        
            '& h3': {
              fontSize: 24,
              
            },
        
            '& h4': {
              fontSize: 22,
              marginTop: 30,
            },
        },
        a: {
         
          // color: '#ff8000',
          color: 'rgba(44,70,107,1)',
          fontFamily: 'century-gothic, sans-serif',
          // transition: 'all 0.3s ease',
          textDecoration: 'none',
          '&:hover': {
            color: '#ff8000',
            '-webkit-text-stroke-width': '1px',
            textStroke: 1,
            '-webkit-text-stroke-color': '#ff8000',
          
          },
        },
        
       
        
        '.tempHide': {
          visibility: 'hidden',
        },
        
        '.text-align-right': {
          textAlign: 'right',
        },
        '.text-align-left': {
          textAlign: 'left',
        }
      },
    },
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: 'rgb(44, 70, 107)',
          color: '#ff8000',
          '-webkit-text-stroke': '1px',
          '-webkit-text-stroke-color': '#ff8000',
          '-webkit-text-stroke-width': '1',
        },
      },
    },

    MuiCheckbox: {
      root: {
        // color: "#ff8000"
      },

      colorSecondary: {
        '&.Mui-checked': {
          color: "#ff8000"
          // color: "#2c466b",
        }
      }
    },

    MuiAccordionSummary: {
      expandIcon: {
        marginRight: 0,
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(44, 70, 107, 0.3)',
      }
    },
    MuiIconButton: {
      label: {
        fontFamily: 'century-gothic, sans-serif',
      },
    },
    MuiFab: {
      primary: {
        border: '1px solid white',
        '&:hover': {
          backgroundColor: 'rgb(255,128,0)',
          color: '#2c466b',
          // borderColor: '#2c466b',
        }
      }
    }
  },
});

const Layout = ({ children, props }) => {
  const context = React.useContext(I18nextContext);
  const language = context.language;
  const {t} = useI18next();

  globalStyles()
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          tagline
          description
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
      <CssBaseline />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/kgw6ksd.css"/>
        <link rel="stylesheet" href="../styles/global.css"/>
        {/*<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />*/}
      </Helmet>
      <Header brand={data.site.siteMetadata.title} tagline={data.site.siteMetadata.tagline}/>
      <div className="mainContainer">
        <main >{children}</main>
        
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText={t("COOKIE-BUTTON")}
          cookieName="gatsby-gdpr-google-tagmanager"
          style={{ background: "rgba(44,70,107,1)" }}
          buttonStyle={{ color: "rgba(44,70,107,1)", fontSize: "13px", background: "rgb(172, 212, 51)"}}
          expires={150}
          // onAccept={() => {
          //   alert("Accept was triggered by clicking the Accept button");
          // }}
          // debug={true}
        >
          {t("COOKIE-MESSAGE")}
          {/*<span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>*/}
        </CookieConsent>
      </div>
      </ParallaxProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout